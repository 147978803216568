.container {
  background: linear-gradient(180deg, #04070a 0%, #000000 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  gap: 1.5rem;
}

.container > h3 {
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.60225rem; /* 106.818% */
  letter-spacing: 0.10681rem;
  text-transform: uppercase;
  background: linear-gradient(113deg, #2EBAE2 0%, #145568 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.list {
  list-style: disc;
  padding: 15px 20px;
}

.alice-carousel__dots {
  display: none;
}

.alice-carousel__stage-item {
  margin: 0 16px;
}

.alice-carousel__stage-item:first-child {
  margin-left: 40px;
}

.alice-carousel__stage-item:last-child {
  margin-right: 40px;
}

.carousel-img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.coinsultIcon {
  margin-left: 50px;
}

.coinmarketcapIcon {
  scale: 1.3;
}

.bnbIcon {
  margin-top: 5px;
}



.container > img {
  width: 20%;
}

.sections,
.socials {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.sections > p {
  font-family: ZCOOL KuaiLe;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.20169rem; /* 150% */
  transition: color 0.3s ease;
}

.sections > p:hover {
  color: #DE5800;
}

.socials {
  margin-bottom: 0px;
}

.socials *,
.sections * {
  cursor: pointer;
}

.sections > div {
  font-weight: 600;
  transition: color 0.3s ease;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
}

.sections option {
  font-family: Poppins;
  border: none;
  background-color: var(--black);
}

@media (max-width: 768px) {
  .sections {
    width: 60%;
  }
}
