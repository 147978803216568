.container {
  background: #0a0a0a;
  padding: 5rem 0rem;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  overflow: auto;
}

.container::-webkit-scrollbar {
  display: none;
}

.container > div > h1 {
  margin-bottom: 0rem;
}

.container > div:nth-child(2) > img {
  animation: zoomInOut 4s infinite;
}

.container > div {
  width: 40%;
}

.container > div:nth-child(1) > img {
  display: none;
}

.container img {
  width: 60%;
  float:right;
}

.read-more {
  animation: expandCollapse 0.3s ease-in-out;
}

.container > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-family: ZCOOL KuaiLe;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 119%; /* 1.785rem */
}

.container > div:nth-child(1) span {
  color: rgba(255, 252, 253, 0.59);
  font-family: ZCOOL KuaiLe;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 119%;
  cursor: pointer;
}

.container > div:nth-child(1) > div {
  border-radius: 1.36994rem;
  background: linear-gradient(125deg, #d51b46 11.92%, #ee6910 83.65%);
  padding: 1rem;
  outline: none;
  width: fit-content;
  border: none;
  font-size: 1.25188rem;
  margin-top: 2rem;
}

.container > div:nth-child(1) option {
  border: none;
  background-color: var(--black);
}

@media (max-width: 768px) {
  .container > div:nth-child(1) > img {
    display: block;
    animation: zoomInOut 4s infinite;
  }

  .container > div:nth-child(1) {
    align-items: center;
    width: 80%;
  }

  .container > div:nth-child(1) > select {
    margin-top: 2rem;
    width: 50%;
  }

  .container > div:nth-child(2) {
    display: none;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes expandCollapse {
  0% {
    max-height: 0;
    opacity: 0;
  }
  100% {
    max-height: 1000px; /* Adjust the value to fit your content height */
    opacity: 1;
  }
}
