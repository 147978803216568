.container {
  background: #0a0a0a url('../../assets/images/roadmap_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.container > h1 {
  font-family: Shojumaru !important;
}

.roadmap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 2rem;
  margin: auto;
  width: 80%;
  max-height: 27vw;
  overflow: hidden;
}

.card {
  position: relative;
  border-radius: 10px;
}

.card > span {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: calc(100% - 2rem);
  word-wrap: break-word;
  white-space: break-spaces;
}

.card h4 {
  font-family: Inter;
  font-size: 1.37188rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.05781rem; /* 150% */
  margin-bottom: 1rem;
}

.card p {
  color: #aeb1b8;
  font-family: Manrope;
  font-size: 1.0975rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.64625rem; /* 150% */
}

.image {
  opacity: 1;
  width: 100%;
}

@media (max-width: 768px) {
  .roadmap {
    grid-template-columns: repeat(2, 1fr);
    max-height: 57vw;
  }
}
