@import url('https://fonts.googleapis.com/css2?family=Inter&family=Shojumaru&family=Poppins&family=ZCOOL+KuaiLe&family=Manrope&family=Roboto+Mono&display=swap');

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  /* font-family: ZCOOL KuaiLe; */
  font-family: Poppins !important;
  color: #fffcfd;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}

:root {
  --red: #ed0137;
  --white: #fffcfd;
  --black: #191818;
}

/* Global classes */
.heading {
  font-family: Shojumaru !important;
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 119%; /* 3.2725rem */
  --clr: var(--red);
  --clr2: #f05733;
  background-clip: text;
  background: #84BCCE -webkit-gradient(linear, left top, right top, from(var(--clr)), to(var(--clr2)), color-stop(0.5, var(--clr2))) 0 0 no-repeat;
  -webkit-background-clip: text;
  background-size: 120px;
  -webkit-background-size: 120px;
  -webkit-text-fill-color: transparent;
  animation: animate-gradientTxt 4s infinite;
}

.select {
  z-index: 10;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
}

.select > img {
  height: 100%;
  width: 1rem;
}

.options {
  background-color: rgb(39, 36, 36);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 1rem;
  z-index: 10;
  left: 0;
  overflow: hidden;
  top: calc(100% + 0.5rem);
}

.options > p {
  padding: 0.75rem 1rem;
  z-index: 10;
  order: 1;
  width: 100%;
}

.options > p:hover {
  background: linear-gradient(125deg, #ed0137 20.07%, #f05733 75.59%);
}

@keyframes animate-gradientTxt {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

@media screen and (max-width: 1600px) {
  :root {
    font-size: 16px;
  }
}
@media screen and (max-width: 1300px) {
  :root {
    font-size: 14px;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  :root {
    font-size: 10px;
  }
}
@media screen and (max-width: 400px) {
  :root {
    font-size: 8px;
  }
}
