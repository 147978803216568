.top {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  z-index: 5;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('../../assets/images/top_bg1.jpg');
}

.top > h1 {
  text-align: center;
  font-family: Shojumaru !important;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 119%; /* 3.34688rem */
  width: 90%;
}

@keyframes backgroundAnimation {
  0%,
  100% {
    background-image: url('../../assets/images/top_bg1.jpg');
  }
  33% {
    background-image: url('../../assets/images/top_bg2.jpg');
  }
  66% {
    background-image: url('../../assets/images/top_bg3.jpg');
  }
}

@media (max-width: 768px) {
  .top > h1 {
    border-radius: 1.25rem;
    background: rgba(36, 35, 35, 0.4);
    padding: 4rem;
  }
}
